import gql from "graphql-tag";

export default gql`
  query article($siteId: [QueryArgument], $slug: [String]) {
    article: entry(slug: $slug, siteId: $siteId) {
      id
      ... on article_default_Entry {
        title
        articleTitle
        slug
        postDate
        icon {
          url
        }
        image {
          ... on images_Asset {
            url
          }
        }
        displayImage
        caption
        bodyCopy {
          ... on bodyCopy_textBlock_BlockType {
            id
            typeHandle
            text
          }
          ... on bodyCopy_imageBlock_BlockType {
            id
            image {
              url
            }
            caption: imageCaption
          }
          ... on bodyCopy_collapsableBlock_BlockType {
            id
            typeHandle
            title: blockTitle
            text: blockContent
            excerpt: childExcerpt
            slug: childSlug
          }

          ... on bodyCopy_blocVideoLink_BlockType {
            id
            src: videoLink
          }

          ... on bodyCopy_soundcloudBlock_BlockType {
            id
            src: soundcloudLink
          }

          ... on bodyCopy_arrayBlock_BlockType {
            id
            img: arrayPicture {
              url
            }
            imgEn: arrayPictureEn {
              url
            }
          }
        }
        articleTags {
          ... on tags_Tag {
            title
          }
        }
        topicCategory {
          url
        }

        seoArticle {
          description
          title
        }
      }
    }
  }
`;
